$(document).ready(function() {
	// создаем клон меню и ставим его туда же, на то же место, что и оригинальное меню.
	$('.navigation').addClass('original').clone().insertAfter('.navigation').addClass('cloned').css('position','fixed').css('top','0').css('margin-top','0').css('z-index','500').removeClass('original').hide();

	setInterval(stickIt, 10);

	function stickIt() {

		let orgElementPos = $('.original').offset();
		let orgElementTop = orgElementPos.top;

		if ($(window).scrollTop() >= (orgElementTop)) {
			// scrolled past the original position; now only show the cloned, sticky element.

			// Cloned element should always have same left position and width as original element.
			let orgElement = $('.original');
			let coordsOrgElement = orgElement.offset();
			let leftOrgElement = coordsOrgElement.left;
			let widthOrgElement = orgElement.css('width');
			$('.cloned').css('left',leftOrgElement+'px').css('top',0).css('width',widthOrgElement).show();
			$('.original').css('visibility','hidden');
		} else {
			// not scrolled past the menu; only show the original menu.
			$('.cloned').hide();
			$('.original').css('visibility','visible');
		}
	}
});